$(document).ready(function(){

    // Cookie setting script wrapper
    /*var cookieScripts = function () {

        // Loading external javascript file
        $.cookiesDirective.loadScript({
            uri:'external.js',
            appendTo: 'eantics'
        });
    };

    $.cookiesDirective({
        privacyPolicyUri: 'myprivacypolicy.html',
        explicitConsent: false,
        position : 'bottom',
        scriptWrapper: cookieScripts,
        backgroundColor: '#000000',
        linkColor: '#ffffff',
        duration: 100000000000,
        message: "Gure zerbitzuak hobetzeko, gure eta hirugarrenen cookieak erabiltzen ditugu, eta iraunkorrak direnez, " +
        "erabiltzaileei buruzko estatistikak ematen dizkigute. Nabigatzen jarraitzen baduzu, cookie horiek erabiltzea onartzen duzu."
    });*/
});