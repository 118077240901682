//Contact form from footer
$('.contact').submit(function(event)
{
    //Stop propagation
    event.preventDefault();

    //Get form attributes
    var form = $(this);
    var url = form.prop( 'action' );
    var type = form.attr( 'method' );

    //Process the form
    $.ajax({
            type: type,
            url: url,
            data: form.serialize(),
            dataType: 'json',
            encode: true
        })
        //If success
        .done(function(data)
        {
            displaySuccessMessage(data.message);

            //Clean form and show message
            $('.contact')[0].reset();

        }).fail(function(data) { //any errors?

        displayErrors('contact', data);

    });

});


function displayErrors(errorBox, data)
{
    var response = JSON.parse(data.responseText);
    var errorString = '<div class="alert alert-danger alert-dismissable" id="'+errorBox+'-alerts-hidden">' +
        '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' +
        '<ul id="contact-validation-errors">';

    $.each( response, function( key, value) {
        errorString += '<li>' + value + '</li>';
    });

    errorString += '</ul></div>';

    $('#'+errorBox+'-validation-errors').append(errorString);
    $('#'+errorBox+'-alerts-hidden').css('display','block');

}



function displaySuccessMessage(message)
{
    var notificationContainer = $('#notification-message');
    var msg = '<div class="alert alert-success alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>'+message+'</div>';
    notificationContainer.empty().append(msg).css('display', 'block');

}
