$(document).ready(function()
{
    var widgetsLoad = $('.widget-load');

    $.each(widgetsLoad, function( index, value )
    {
        $.ajax({
            type: 'GET',
            url: $(this).attr('data-url'),
            success: function(data)
            {
                $('#'+value.id).html(data.renderedHtml);
            }
        });

    });


});
