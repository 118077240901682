$(document).ready(function () {

    $(".more").on('click',function(){

        var clicked = $(this);
        if(clicked.hasClass('less'))
        {
            clicked.removeClass('less');
            clicked.text("gehio").siblings(".complete").css('display','none');
            clicked.siblings(".incomplete").css('display','block');

        }else{

            clicked.addClass('less');
            clicked.text("gutxiago").siblings(".complete").css('display','block');
            clicked.siblings(".incomplete").css('display','none');
        }

    });

});
